import { Injectable } from '@angular/core';
import { AlertController, Platform } from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class InstallPromptService {
  protected deferredPrompt: any;
  protected alert: any;

  constructor(
    protected alertController: AlertController,
    protected platform: Platform,
  ) { }

  init() {
    if(localStorage.getItem("preventInstallPrompt") !== "true") {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        // Prevent install prompt even if the listener has already been added.
        if(localStorage.getItem("preventInstallPrompt") !== "true") {
          this.deferredPrompt = e;
          this.prompt();
          localStorage.setItem("preventInstallPrompt", "true");
        }
      });
    }
  }

  protected async prompt(): Promise<boolean> {
    let store = "";
    if (this.platform.is("android")) {
      store = ", or else visit the Google Play store";
    } else if (this.platform.is("ios")) {
      store = ", or else visit the Apple Store";
    }

    this.alert = await this.alertController.create({
      cssClass: "prompt-alert",
      header: "Install the Mobile App?",
      message: `To install, press "Install" on the prompt that says "Add to Home Screen"${store}.`,
      buttons: [
        {
          text: "CONTINUE TO PROMPT",
          cssClass: "continue-button",
          handler: () => this.deferredPrompt.prompt(),
        },
        {
          text: "CANCEL",
          cssClass: "cancel-button",
          role: "cancel",
          handler: () => this.alert.dismiss(),
        },
      ],
    });
    this.alert.present();

    return this.deferredPrompt.userChoice
      .then((choice) => {
        this.alert.dismiss();
        return choice;
      });
  }
}
