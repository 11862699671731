import { Plugins } from "@capacitor/core";

// Must import the package once to make sure the web support initializes
import { Injectable } from "@angular/core";
import "@capacitor-community/firebase-analytics";

const { FirebaseAnalytics } = Plugins;

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  disable(): Promise<void> {
    return FirebaseAnalytics.setCollectionEnabled({ enabled: false });
  }

  enable(): Promise<void> {
    return FirebaseAnalytics.setCollectionEnabled({ enabled: true });
  }

  instance(): Promise<{ instanceId: string }> {
    return FirebaseAnalytics.getAppInstanceId();
  }

  logEvent(name: string, params?: any): Promise<void> {
    return FirebaseAnalytics.logEvent({ name, params });
  }

  reset(): Promise<void> {
    return FirebaseAnalytics.reset();
  }

  setScreen(screenName: string, nameOverride?: string): Promise<void> {
    return FirebaseAnalytics.setScreenName({ screenName, nameOverride });
  }

  setUserID(userId: string): Promise<void> {
    return FirebaseAnalytics.setUserId({ userId });
  }

  setUserProperty(name: string, value: string): Promise<void> {
    return FirebaseAnalytics.setUserProperty({ name, value });
  }

  initializeFirebase(options: {
    apiKey: string,
    authDomain: string,
    databaseURL?: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId: string,
  }): Promise<void> {
    return FirebaseAnalytics.initializeFirebase(options);
  }
}
