import { Component } from "@angular/core";
import { AnalyticsService } from "./services/analytics.service";
import { InstallPromptService } from "./services/install-prompt.service";
import { Capacitor } from '@capacitor/core';
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    protected analytics: AnalyticsService,
    protected installPrompt: InstallPromptService,
  ) {
    this.initializeApp();
  }

  protected async initializeApp(): Promise<void> {
    if (Capacitor.getPlatform() === "web") {
      this.installPrompt.init();
      await this.analytics.initializeFirebase(environment.firebase);
    }
    await this.analytics.enable();
  }
}
