// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBkA88Yb5BMqheq-_BbvTSAWVI0Ov4l7_c",
    authDomain: "io-mesur-manual-prd.firebaseapp.com",
    projectId: "io-mesur-manual-prd",
    storageBucket: "io-mesur-manual-prd.appspot.com",
    messagingSenderId: "52034538449",
    appId: "1:52034538449:web:932cc2093f4c72fc75eef0",
    measurementId: "G-LS3VZMVXGF",
  },
  azure: {
    docsStorage: "mesurpubshare.blob.core.windows.net",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
