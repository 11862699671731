import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then( m => m.HomePageModule)
  },
  {
    path: "weed-modal",
    loadChildren: () => import("./modals/weed-modal/weed-modal.module").then( m => m.WeedModalPageModule)
  },
  {
    path: "grass-modal",
    loadChildren: () => import("./modals/grass-modal/grass-modal.module").then( m => m.GrassModalPageModule)
  },
  {
    path: "chem-modal",
    loadChildren: () => import("./modals/chem-modal/chem-modal.module").then( m => m.ChemModalPageModule)
  },
  {
    path: "ornamental-modal",
    loadChildren: () => import("./modals/ornamental-modal/ornamental-modal.module").then( m => m.OrnamentalModalPageModule)
  },
  {
    path: "modal",
    loadChildren: () => import("./modals/modal/modal.module").then( m => m.ModalPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
